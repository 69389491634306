import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/index'
import Pagina from '../components/Pagina'

// Styles
import './styles/servicos.scss'

const Servicos = ({ data }) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Institucional' },
      { path: '/servicos/', name: 'Serviços' }
    ]
  }

  return (
    <Layout breadCrumb={breadC} >
      <Pagina pagina={data.pagina}/>
      <div className="container mt-4 pb-5">
        <section>
          <h2 className="font-size-25 text-black">Serviços</h2>
          <p>O Spani conta com uma grande e estratégica rede de fornecedores localizados em todo o país e um moderno sistema de prestação de serviços e de logística. Confira algumas vantagens:</p>
          <div className="my-5">
            <h3>Variedade</h3>
            <p>Mais de 10 mil itens. Um mix de produtos específicos para o seu setor, garantidos por um estoque seguro, embalagens apropriadas.</p>
          </div>
          <div className="mb-5">
            <h3>Atendimento Diferenciado</h3>
            <p>Facilidade na hora da compra e no pagamento. O Spani oferece um atendimento especial, sem burocracia. Tudo para que você tenha o máximo de comodidade, praticidade e preços baixos. Além disso, a Rede conta com uma equipe que está pronta para ouvir os seus clientes, sanar dúvidas e oferecer soluções rápidas de atendimento.</p>
          </div>
          <div className="mb-5">
            <h3>Promoções e Preços Baixos</h3>
            <p>Grande economia para maximizar os lucros do seu estabelecimento. São mais de 300 itens em promoção toda semana, em todos os setores da loja. O Spani aceita cartões de débito ou crédito, cartões alimentação ou dinheiro. Os pagamentos realizados com cheques são mediante cadastro e aprovação de nosso departamento financeiro. Para saber mais consulte o balcão de atendimento da loja mais próxima de você.</p>
          </div>
          <div className="mb-5">
            <h3>Consultoria Empresarial</h3>
            <p>Uma parceria entre o Spani Atacadista e o SEBRAE proporciona atendimento para futuros empreendedores e clientes da Rede que queiram aperfeiçoar os projetos de sua empresa. O projeto ‘Sebrae Móvel’, que visita as lojas frequentemente, atende gratuitamente empresários, empreendedores e cidadãos interessados em obter informações e orientações sobre assuntos relacionados ao meio empresarial, como gestão de empresas e esclarecimentos de dúvidas para quem deseja iniciar o próprio negócio.</p>
          </div>
          <div className="mb-5">
            <h3>Compre sem sair do seu negócio</h3>
            <p>A Rede conta com o Spani Express: uma equipe de vendas externas para atender aos pequenos varejistas no próprio estabelecimento comercial, recebendo visitas periódicas dos profissionais.</p>
          </div>
          <div className="mb-5">
            <h3>Nós entregamos</h3>
            <p>O Spani entrega as suas compras em seu estabelecimento com rapidez e segurança. Uma grande comodidade. Para saber mais informações sobre tarifas, consulte o balcão de atendimento da loja mais próxima de você.</p>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Servicos

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/servicos/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
    desktop {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    mobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 414, height: 310, layout: CONSTRAINED)
        }
      }
    }
  }
}
`
